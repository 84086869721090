import React from 'react'
import WebExample from '../assets/websiteexample.png'
import CSharpApp from '../assets/csharpapp.png'

const Projects = () => {
  return (
    <div name='projects' className='w-full h-screen grid items-center md:h-screen bg-lightgray'>
       
        <div className='max-w-[1200px] mx-auto p-12 flex flex-col justify-center w-full md:h-4/5 rounded-md md:bg-gray bg-lightgray md:shadow-lg md:shadow-darkred'>
            
            <div className='pb-8'>
                <p className='text-4xl font-bold inline border-b-4 border-lightred'>Projects</p>
                <p className='py-8'>Here is a few projects that I have worked on:</p>
            </div> 

            {/* Container */}
            <div className='grid grid-cols-2 gap-4'>

                {/* Grid item */}
                <div style={{backgroundImage: `url(${WebExample})`}} className='shadow-lg shadow-darkred border-4 border-lightred group container rounded-md flex justify-center items-center mx-auto content-div'>
                    {/* Hover effects */}
                    <div className='opacity-0 group-hover:opacity-100 duration-500 text-center'>
                        <span className='text-2xl font-bold text-center'>ASP.NET Website
                        </span>
                        {/*<div className='pt-8 text-center'>
                            <a href="/">
                                <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                                Code</button>
                            </a>
                        </div>*/}
                    </div>
                </div>

                {/* Grid item */}
                <div style={{backgroundImage: `url(${CSharpApp})`}} className='shadow-lg shadow-darkred border-4 border-lightred group container rounded-md flex justify-center items-center mx-auto content-div'>
                    {/* Hover effects */}
                    <div className='opacity-0 group-hover:opacity-100 duration-500 text-center'>
                        <span className='text-2xl font-bold'>C# App that modifies a SQL Server database
                        </span>
                        {/*<div className='pt-8 text-center'>
                            <a href="/">
                                <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                                Code</button>
                            </a>
                        </div>*/}
                    </div>
                </div>

            </div> 

        </div>

    </div>
  )
}

export default Projects