import React from 'react'

const About = () => {
  return (
    <div name='about' className='w-full h-screen grid items-center bg-lightgray'>
        
        {/* Container */}
        <div className='max-w-[1200px] mx-auto p-12 flex flex-col justify-center md:h-4/5  rounded-md md:bg-gray bg-lightgray md:shadow-lg md:shadow-darkred'>
            
            <div className=' w-full grid grid-cols-2 gap-8 pb-4'>
                <div className='pb-8 pl-4'>
                    <p className='text-4xl font-bold py-4'>About</p>
                </div>

                <div>
                    <p >Hi there, I'm Grayson and I love to code! I was first interested in designing video games which led me to tinker around in the game engine for fun. 
                        It wasn't long after that I decided that Software Development was the path for me and I enrolled in proper programming classes.</p>
                </div>
            </div>

            <div className='w-full grid grid-cols-2 gap-8 p-4'>
                <div className='text-3xl font-bold'>
                    <p className='text-align-center py-4'>Education</p>
                </div>
                <div className='border-t-4 border-t-lightred py-4'>
                    <p className='text-2xl'>Object-Oriented Software Development Certificate</p>
                    <p>Southern Alberta Institute of Technology</p>
                    <p className='text-2xl py-2'>Mechanical Engineering Technology Diploma</p>
                    <p>Red Deer Polytechnic</p>
                </div>
            </div> 
  
        </div>

    </div>
  )
}

export default About