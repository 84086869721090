import React , {useState} from 'react'
import {FaBars, FaTimes} from 'react-icons/fa'
import {Link} from 'react-scroll'
import GR from '../assets/GR.png'

const Navbar = () => {
  const [nav, setNav] = useState(false)
  const handleClick = () => setNav(!nav)

  return (
    <div className='fixed z-50 w-full h-[80px] flex justify-between items-center px-4 bg-gray'>
        <div>
            <img src={GR} alt='Logo' style={{width: '80px'}}/>
        </div>

        {/* menu */}
        <div className='hidden md:flex'>
          <ul className='hidden md:flex'>
            <li className='hover:text-pink duration-300'>
              <Link to="home" smooth={true} duration={500}>
                Home
              </Link>
            </li>
            <li className='hover:text-pink duration-300'>
              <Link to="about" smooth={true} duration={500}>
                About
              </Link>
            </li>
            <li className='hover:text-pink duration-300'>
              <Link to="skills" smooth={true} duration={500}>
                Skills
              </Link>
            </li>
            <li className='hover:text-pink duration-300'>
              <Link to="projects" smooth={true} duration={500}>
                Projects
              </Link>
            </li>
            <li className='hover:text-pink duration-300'>
              <Link to="contact" smooth={true} duration={500}>
                Contact
              </Link>
            </li>
          </ul>
        </div>

        {/* hamburger */}
        <div onClick={handleClick} className='md:hidden z-10 duration-300 hover:text-pink'>
          {!nav ? <FaBars class='ham-off' /> : <FaTimes class='ham-on' /> }
        </div>

        {/* mobile menu */}
        <ul className={!nav ? 'hidden' : 'absolute top-0 left-0 w-full h-screen bg-gray flex flex-col justify-center items-center'}>
            <li className='py-6 text-4xl hover:text-pink duration-300'><Link onClick={handleClick} to="home" smooth={true} duration={500}>
                Home
              </Link></li>
            <li className='py-6 text-4xl hover:text-pink duration-300'><Link onClick={handleClick} to="about" smooth={true} duration={500}>
                About
              </Link></li>
            <li className='py-6 text-4xl hover:text-pink duration-300'><Link onClick={handleClick} to="skills" smooth={true} duration={500}>
                Skills
              </Link></li>
            <li className='py-6 text-4xl hover:text-pink duration-300'><Link onClick={handleClick} to="projects" smooth={true} duration={500}>
                Projects
              </Link></li>
            <li className='py-6 text-4xl hover:text-pink duration-300'><Link onClick={handleClick} to="contact" smooth={true} duration={500}>
                Contact
              </Link></li>
          </ul>
    </div>
  )
}

export default Navbar