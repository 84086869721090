import React from 'react'
import {HiArrowNarrowRight} from 'react-icons/hi'
import {Link} from 'react-scroll'

const Home = () => {
  return (
    <div name='home' className='w-full h-screen bg-gray'>

        {/* Container */}
        <div className='max-w-[1200px] mx-auto p-12 flex flex-col justify-center h-full'> 
            
            <p>Hi, my name is </p>
            <h1 className='text-4xl sm:text-7xl font-bold text-pink'>Grayson Ruttan</h1>
            <h2 className='text-3xl sm:text-6xl font-bold text-lightred'>and I'm a Full Stack Developer.</h2>
            <p className='py-4 max-w-700px'>I specialize in building high quality web applications using a variety of frameworks.</p>
            <div>
                <Link to="projects" smooth={true} duration={500}>
                    <button class="btn" className='group border-4 px-6 py-3 my-2 flex items-center hover:bg-pink hover:border-lightred rounded-md duration-300'>View Work 
                        <span className='group-hover:rotate-90 duration-300'>
                         <HiArrowNarrowRight className='ml-3'/>
                     </span>
                    </button>
                </Link>
            </div>
            
        </div>
    </div>
  )
}

export default Home