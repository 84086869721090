import React from 'react'

const Footer = () => {
  return (
    <div className='z-50 w-full h-[50px] flex justify-center items-center px-4 bg-gray text-center'>
        <p>Grayson Ruttan - Made with React and Tailwind</p>
    </div>
  )
}

export default Footer