import React from 'react'
import {HiOutlineMail, HiOutlineDeviceMobile} from 'react-icons/hi'

const Contact = () => {
  return (
    <div name='contact' className='w-full h-screen grid items-center bg-gray'>
      {/*Container */}
      <div className='max-w-[1200px] mx-auto p-12 flex flex-col justify-center w-full md:h-4/5 md:bg-lightgray bg-gray rounded-md md:shadow-lg md:shadow-darkred'>
      
        <div className='pb-8'>
          <p className='text-4xl font-bold inline border-b-4 border-lightred'>Contact</p>
          <p className='py-8'>If you want to reach me please fill out the form below or use my contact info:</p>
        </div>

        <div className='max-w-[1200px] mx-auto py-4 flex flex-cols-2 justify-between w-full h-100'>
          {/* Contact form */}
          <form name="contactForm" method="POST" className='flex flex-col max-w-[600px] w-full' netlify netlify-honeypot="bot-field" hidden>
            <input type="hidden" name="form-name" value="contactForm" />

            <input type="text" placeholder='Name' name='name' required className='bg-white rounded-md p-2'/>
            <input type="email" placeholder='Email' name='email' required className='my-4 p-2 bg-white rounded-md'/>
            <textarea name="message" placeholder='Leave your message here...' rows="10" required className='bg-white p-2 rounded-md'></textarea>

            <button type="submit" class="btn" className='border-4 rounded-md px-6 py-3 my-2 flex text-center hover:bg-pink hover:border-lightred duration-300'>Send Message</button>
          </form>

          <div className='grid grid-cols-1 items-center max-w-[300px] w-full text-center px-8'>
                
                <div className='hover:scale-110 duration-500 p-4 rounded-md shadow-lg shadow-darkred border-4 border-lightred hover:shadow-pink te'>
                    <HiOutlineMail className='w-20 mx-auto my-4' size={32}/>
                    <p className='my-4 break-words'>grayson.ruttan@gmail.com</p>
                </div>

                <div className='hover:scale-110 duration-500 p-4 rounded-md shadow-lg shadow-darkred border-4 border-lightred hover:shadow-pink'>
                    <HiOutlineDeviceMobile className='w-20 mx-auto my-4' size={32}/>
                    <p className='my-4'>+1 (403) 396-5703</p>
                </div>
          </div>
        </div>
        
      </div>
    </div>
  )
}

export default Contact