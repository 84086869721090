import React from 'react'

import HTML from '../assets/html.png';
import CSS from '../assets/css.png';
import JavaScript from '../assets/javascript.png';
import Node from '../assets/node.png';
import ReactImg from '../assets/react.png';
import Tailwind from '../assets/tailwind.png';
import CSharpImg from '../assets/csharplogo.png';
import JavaImg from '../assets/javalogo.png';

const Skills = () => {
  return (
    <div name='skills' className='bg-gray w-full h-full grid items-center sm:h-screen'>
        {/* Container */}
        <div className='max-w-[1200px] mx-auto p-12 flex flex-col justify-center w-full md:h-4/5 rounded-md md:bg-lightgray bg-gray md:shadow-lg md:shadow-darkred'>
            
            <div>
                <p className='text-4xl font-bold inline border-b-4 border-lightred'>Skills</p>
                <p className='py-8'>Here are some of the the technologies I've worked with:</p>
            </div>

            <div className='w-full grid grid-cols-2 sm:grid-cols-4 gap-4 text-center py-4'>
                <div className='grid grid-cols-1 content-end h-[200px] shadow-lg shadow-darkred border-4 border-lightred rounded-md hover:scale-110 hover:shadow-pink duration-500'>
                    <img src={HTML} alt='HTML' className='w-20 mx-auto my-4'/>
                    <p  className='mb-4'>HTML</p>
                </div>

                <div className='grid grid-cols-1 content-end shadow-lg shadow-darkred border-4 border-lightred rounded-md hover:scale-110 hover:shadow-pink duration-500'>
                    <img src={CSS} alt='CSS' className='w-20 mx-auto my-4'/>
                    <p  className='mb-4'>CSS</p>
                </div>

                <div className='grid grid-cols-1 content-end shadow-lg shadow-darkred border-4 border-lightred rounded-md hover:scale-110 hover:shadow-pink duration-500'>
                    <img src={JavaScript} alt='Javascript' className='w-20 mx-auto my-4'/>
                    <p  className='mb-4'>Javascript</p>
                </div>

                <div className='grid grid-cols-1 content-end shadow-lg shadow-darkred border-4 border-lightred rounded-md hover:scale-110 hover:shadow-pink duration-500'>
                    <img src={Node} alt='Node' className='w-20 mx-auto my-4'/>
                    <p  className='mb-4'>Node.js</p>
                </div>

                <div className='grid grid-cols-1 content-end h-[200px] shadow-lg shadow-darkred border-4 border-lightred rounded-md hover:scale-110 hover:shadow-pink duration-500'>
                    <img src={ReactImg} alt='ReactImg' className='w-20 mx-auto my-4'/>
                    <p  className='mb-4'>React</p>
                </div>

                <div className='grid grid-cols-1 content-end shadow-lg shadow-darkred border-4 border-lightred rounded-md hover:scale-110 hover:shadow-pink duration-500'>
                    <img src={Tailwind} alt='Tailwind' className='w-20 mx-auto my-4'/>
                    <p  className='mb-4'>Tailwind</p>
                </div>

                <div className='grid grid-cols-1 content-end shadow-lg shadow-darkred border-4 border-lightred rounded-md hover:scale-110 hover:shadow-pink duration-500'>
                    <img src={CSharpImg} alt='CSharpImg' className='w-20 mx-auto my-4'/>
                    <p  className='mb-4'>C#/C++</p>
                </div>

                <div className='grid grid-cols-1 content-end shadow-lg shadow-darkred border-4 border-lightred rounded-md hover:scale-110 hover:shadow-pink duration-500'>
                    <img src={JavaImg} alt='JavaImg' className='w-20 mx-auto my-4'/>
                    <p  className='mb-4'>Java</p>
                </div>
            </div>
            
        </div>
    </div>
  )
}

export default Skills